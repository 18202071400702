import React from "react"

const Last = () => {
  return (
    <secion id="last">
      <div className="last">
        <h3>
          If you havent already sent some love and appreciation to Jordaan to
          day.. feel free to send her some funds!
        </h3>
        <p>Venmo & CashApp: @j0scott</p>

        <h2>
          Happy Birthday Jordaan! <br></br>Love, Leroy
        </h2>
      </div>
    </secion>
  )
}

export default Last
